import React, { Fragment, lazy, Suspense } from "react";
import { connect } from "react-redux";
import {
  Auth,
  FC_CheckLoggedIn,
  FC_GetSystemInfo,
  FC_Logout,
  System,
  FC_SetError,
  FC_LoadBankSummaryDetails,
  InstructionLetterStore,
} from "./actions";
import { StoreState } from "./reducers";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LazyLoading from "./components/LazyLoading/LazyLoading";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { Homepage } from "./containers/Homepage/Homepage";
import MainLoading from "./components/MainLoading/MainLoading";
import { NavBar } from "./components/NavBar/NavBar";
import SideNavBar from "./components/SideNavBar/SideNavBar";
import AppLoading from "./components/AppLoading/AppLoading";
import { FC_Access, UserAccessList } from "./utils/auth_access";
import Alert, { AlertType } from "./components/Alert/Alert";
import NetworkError from "./components/NetworkError/NetworkError";

//* Components

const Dashboard = lazy(() =>
  import("./containers/Dashboard/Dashboard").then(({ Dashboard }) => ({
    default: Dashboard,
  }))
);
const Register = lazy(() =>
  import("./containers/Register/Register").then(({ Register }) => ({
    default: Register,
  }))
);
const Profile = lazy(() =>
  import("./containers/Profile/Profile").then(({ Profile }) => ({
    default: Profile,
  }))
);
const Banks = lazy(() =>
  import("./containers/Banks/Banks").then(({ Banks }) => ({
    default: Banks,
  }))
);
const CreateInstructionLetter = lazy(() =>
  import("./containers/InstructionLetter/InstructionLetter").then(
    ({ InstructionLetter }) => ({
      default: InstructionLetter,
    })
  )
);
const Users = lazy(() =>
  import("./containers/Users/Users").then(({ Users }) => ({
    default: Users,
  }))
);
const ChangePassword = lazy(() =>
  import("./containers/ChangePassword/ChangePassword").then(
    ({ ChangePassword }) => ({
      default: ChangePassword,
    })
  )
);
const BankSettings = lazy(() =>
  import("./containers/BankSettings/BankSettings").then(({ BankSettings }) => ({
    default: BankSettings,
  }))
);

const UserWallet = lazy(() =>
  import("./containers/UserWallet/UserWallet").then(({ UserWallet }) => ({
    default: UserWallet,
  }))
);

const BankValuersReport = lazy(() =>
  import("./containers/BankValuersReport/BankValuersReport").then(
    ({ BankValuersReport }) => ({
      default: BankValuersReport,
    })
  )
);

//* Interfaces
// props for the component
interface AppProps {
  auth: Auth;
  system: System;
  instructionLetters: InstructionLetterStore;
  FC_CheckLoggedIn: (callBack: (status: boolean) => void) => void;
  FC_Logout: () => void;
  FC_GetSystemInfo: (callback: (loading: boolean) => void) => void;
  FC_SetError: (msg: string) => void;
  FC_LoadBankSummaryDetails: (
    bank_id: string,
    callBack: (loading: boolean) => void
  ) => void;
}

interface AppState {
  loading: boolean;
  showSideNav: boolean;
}

class _App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      loading: false,
      showSideNav: true,
    };
  }

  componentDidMount() {
    //* check if the user is logged in
    this.props.FC_CheckLoggedIn((status: boolean) => {
      if (status === true) {
        if (this.props.system.basic_info === null) {
          this.props.FC_GetSystemInfo((loading: boolean) => {
            this.setState({ loading: loading });
            if (
              loading === false &&
              this.props.instructionLetters.bank_details === null &&
              (FC_Access(this.props.auth, UserAccessList.HAVE_A_BRANCH).view ===
                true ||
                FC_Access(this.props.auth, UserAccessList.HAVE_ONE_BANK)
                  .view === true)
            ) {
              this.setState({ loading: true });
              // GetBank details
              var selected_bank_id = "";
              if (
                this.props.auth.user?.user_bank !== undefined &&
                this.props.auth.user?.user_bank.length > 0
              ) {
                selected_bank_id = this.props.auth.user?.user_bank[0].bank_id;
              } else {
                if (
                  this.props.auth.user?.user_branches !== undefined &&
                  this.props.auth.user?.user_branches.length > 0
                ) {
                  selected_bank_id =
                    this.props.auth.user?.user_branches[0].bank_id;
                }
              }
              if (selected_bank_id !== "") {
                this.props.FC_LoadBankSummaryDetails(
                  selected_bank_id,
                  (loaderState: boolean) =>
                    this.setState({ loading: loaderState })
                );
              } else {
                this.setState({ loading: false });
              }
            }
          });
        }
      }
    });
  }

  render() {
    // constants
    const authenticationPath = "/login";

    if (this.props.auth.loading === true) {
      return <MainLoading />;
    }

    if (
      this.state.loading === true ||
      (this.props.auth.isAuthenticated === true &&
        this.props.system.basic_info === null)
    ) {
      return <AppLoading />;
    }
    return (
      <Fragment>
        <Router>
          <div className="h-screen">
            {this.props.system.error !== "" && (
              <div className="fixed right-3 top-3 z-50 animate__animated animate__zoomInUp animate__fast">
                <Alert
                  alertType={AlertType.DANGER}
                  title={"Error occurred!"}
                  description={this.props.system.error}
                  close={() => this.props.FC_SetError("")}
                />
              </div>
            )}
            {/* Check connectivity */}
            {navigator.onLine === false && <NetworkError />}
            {this.props.auth.isAuthenticated === true && (
              <NavBar
                auth={this.props.auth}
                FC_Logout={this.props.FC_Logout}
                instructionLetters={this.props.instructionLetters}
                FC_LoadBankSummaryDetails={this.props.FC_LoadBankSummaryDetails}
                showSideNav={this.state.showSideNav}
                setShowSideNav={(showSideNav: boolean) =>
                  this.setState({ showSideNav: showSideNav })
                }
              />
            )}
            <div
              className={`${
                this.props.auth.isAuthenticated === true
                  ? `bg-gray-200 h-full ${
                      this.state.showSideNav === true ? "lg:pl-64" : ""
                    } pt-14 overflow-y-auto`
                  : ""
              }`}
              style={{ transition: "0.7s" }}
            >
              {this.props.auth.isAuthenticated === true &&
                this.state.showSideNav === true && (
                  <SideNavBar
                    auth={this.props.auth}
                    showSideNav={this.state.showSideNav}
                    setShowSideNav={(showSideNav: boolean) =>
                      this.setState({ showSideNav: showSideNav })
                    }
                  />
                )}
              <div
                className={`${
                  this.props.auth.isAuthenticated === true
                    ? "p-1 md:p-2 h-full container mx-auto"
                    : ""
                }`}
              >
                <Switch>
                  <Route exact path="/" component={Homepage} />
                  <Route exact path="/login" component={Homepage} />
                  <Suspense fallback={<LazyLoading />}>
                    <ProtectedRoute
                      path="/dashboard"
                      component={Dashboard}
                      isAuthenticated={this.props.auth.isAuthenticated}
                      authenticationPath={authenticationPath}
                      loading={this.state.loading}
                      exact
                    />
                    {FC_Access(this.props.auth, UserAccessList.REGISTER_USER)
                      .view === true && (
                      <ProtectedRoute
                        path="/register-user"
                        component={Register}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    <ProtectedRoute
                      path="/profile"
                      component={Profile}
                      isAuthenticated={this.props.auth.isAuthenticated}
                      authenticationPath={authenticationPath}
                      loading={this.state.loading}
                      exact
                    />
                    {FC_Access(this.props.auth, UserAccessList.BANKS_MANAGEMENT)
                      .view === true && (
                      <ProtectedRoute
                        path="/banks-management"
                        component={Banks}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {FC_Access(
                      this.props.auth,
                      UserAccessList.INSTRUCTION_LETTERS
                    ).view === true && (
                      <ProtectedRoute
                        path="/instruction-letter"
                        component={CreateInstructionLetter}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    <ProtectedRoute
                      path="/change-password"
                      component={ChangePassword}
                      isAuthenticated={this.props.auth.isAuthenticated}
                      authenticationPath={authenticationPath}
                      loading={this.state.loading}
                      exact
                    />
                    {(FC_Access(this.props.auth, UserAccessList.REGISTER_USER)
                      .view === true ||
                      FC_Access(this.props.auth, UserAccessList.SYSTEM_USERS)
                        .view === true) && (
                      <ProtectedRoute
                        path="/users"
                        component={Users}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {FC_Access(this.props.auth, UserAccessList.BANK_SETTINGS)
                      .view === true && (
                      <ProtectedRoute
                        path="/bank-settings"
                        component={BankSettings}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {FC_Access(this.props.auth, UserAccessList.USER_WALLET)
                      .view === true && (
                      <ProtectedRoute
                        path="/online-wallet"
                        component={UserWallet}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {FC_Access(this.props.auth, UserAccessList.BANK_SETTINGS)
                      .view === true && (
                      <ProtectedRoute
                        path="/bank-valuers-report"
                        component={BankValuersReport}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                  </Suspense>
                </Switch>
              </div>
            </div>
          </div>
        </Router>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  auth,
  system,
  instructionLetters,
}: StoreState): {
  auth: Auth;
  system: System;
  instructionLetters: InstructionLetterStore;
} => {
  return {
    auth,
    system,
    instructionLetters,
  };
};

export const App = connect(mapStateToProps, {
  FC_CheckLoggedIn,
  FC_Logout,
  FC_GetSystemInfo,
  FC_SetError,
  FC_LoadBankSummaryDetails,
})(_App);
