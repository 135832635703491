import React, { Component } from "react";
import LOGO from "../../assets/VMS.png";
import {
  AiFillEye,
  AiOutlineEyeInvisible,
  AiOutlineLoading3Quarters,
  AiOutlineLogin,
} from "react-icons/ai";
import { StoreState } from "../../reducers";
import { Auth, FC_GetSystemInfo, FC_Login, System } from "../../actions";
import { connect } from "react-redux";
import Alert, { AlertType } from "../../components/Alert/Alert";
import { Redirect } from "react-router";

interface AppProps {
  auth: Auth;
  system: System;
  FC_Login: (
    data: {
      username: string;
      password: string;
    },
    CallbackFunc: Function
  ) => void;
  FC_GetSystemInfo: (callback: (loading: boolean) => void) => void;
}

interface AppState {
  redirect: boolean;
  username: string;
  password: string;
  loading: boolean;
  error: {
    target: string | null;
    msg: string;
  };
  passwordDisplay: boolean;
}

class _App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      redirect: false,
      username: "",
      password: "",
      loading: false,
      error: {
        target: null,
        msg: "",
      },
      passwordDisplay: false,
    };
  }
  LoginFn = async (e: any) => {
    e.preventDefault();
    if (this.state.username === "") {
      return this.setState({
        error: {
          target: "username",
          msg: "Please fill phone number or email",
        },
      });
    }
    if (this.state.password === "") {
      return this.setState({
        error: {
          target: "password",
          msg: "Please fill password",
        },
      });
    }
    if (this.state.username !== "" && this.state.password !== "") {
      this.setState({ loading: true });
      this.props.FC_Login(
        { username: this.state.username, password: this.state.password },
        (status: boolean, msg: string) => {
          status === false &&
            this.setState({
              error: {
                target: "main",
                msg: msg,
              },
            });
          if (status === true) {
            this.props.system.basic_info === null &&
              this.props.FC_GetSystemInfo((loading_status: boolean) => {
                this.setState({ loading: loading_status });
                loading_status === true &&
                  this.props.system.basic_info !== null &&
                  this.setState({ redirect: true });
              });
          } else {
            this.setState({ loading: false });
          }
        }
      );
    }
  };
  componentDidMount = () => {};
  render() {
    if (
      this.props.auth.isAuthenticated === true ||
      this.state.redirect === true
    ) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <div className="h-screen bg-hero-pattern bg-cover">
        <div className="bg-black h-full bg-opacity-30">
          <div className="grid grid-cols-12 h-full">
            <div className="hidden lg:block h-full col-span-12 lg:col-span-7"></div>
            <div className="h-full col-span-12 lg:col-span-5 bg-white overflow-y-auto">
              <div className="container max-w-screen-2xl mx-auto">
                <div className="flex flex-col md:flex-row items-center gap-4 justify-start md:px-6 text-primary-800 mt-3 mb-10 animate__animated animate__fadeIn bg-primary-50 m-2 md:mx-3 rounded-md md:p-4 p-2 md:py-6">
                  <div>
                    <img src={LOGO} alt="VMS-LOGO" className="w-28" />
                  </div>
                  <div className="flex flex-col text-center md:text-left">
                    <span className="text-lg md:text-2xl font-bold">
                      Valuation Management
                    </span>
                    <span className="text-lg md:text-2xl font-bold">
                      System
                    </span>
                  </div>
                </div>
                <form onSubmit={this.LoginFn}>
                  <div className="mt-5 mx-3 md:mx-3 rounded-md bg-gray-100 p-4 px-4 md:px-4 z-30 animate__animated animate__zoomIn animate__faster">
                    <div className="font-bold text-xl mb-4">
                      SIGN IN ACCOUNT
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="font-light text-base">
                        Phone number or email
                      </span>
                      <input
                        type="text"
                        value={this.state.username}
                        onChange={(e) => {
                          this.setState({ username: e.target.value });
                          this.state.error.target !== null &&
                            this.setState({
                              error: { target: null, msg: "" },
                            });
                        }}
                        disabled={this.state.loading}
                        autoFocus={true}
                        className={`border ${
                          this.state.error.target === "username"
                            ? "border-red-300"
                            : "border-gray-500 bg-white"
                        } ${
                          this.state.loading === true
                            ? "cursor-not-allowed"
                            : ""
                        }  bg-white text-black rounded-md px-3 py-2`}
                      />
                      <div>
                        {this.state.error.target === "username" && (
                          <Alert
                            alertType={AlertType.DANGER}
                            title={"Error"}
                            description={this.state.error.msg}
                            close={() => {
                              this.setState({
                                error: { target: null, msg: "" },
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 mb-5 w-full mt-4">
                      <span className="font-light text-base">Password </span>
                      <div className="relative w-full">
                        <input
                          type={
                            this.state.passwordDisplay === true
                              ? "text"
                              : "password"
                          }
                          value={this.state.password}
                          disabled={this.state.loading}
                          onChange={(e) => {
                            this.setState({ password: e.target.value });
                            this.state.error.target !== null &&
                              this.setState({
                                error: { target: null, msg: "" },
                              });
                          }}
                          className={`border ${
                            this.state.error.target === "password"
                              ? "border-red-300"
                              : "border-gray-500 bg-white"
                          } ${
                            this.state.loading === true
                              ? "cursor-not-allowed"
                              : ""
                          } bg-white text-black rounded-md px-3 py-2 w-full`}
                        />
                        <div
                          onClick={() =>
                            this.setState({
                              passwordDisplay: !this.state.passwordDisplay,
                            })
                          }
                          className="absolute inset-y-0 right-0 pr-3 flex items-center leading-5 text-3xl cursor-pointer text-primary-900"
                        >
                          {this.state.passwordDisplay === false ? (
                            <AiFillEye />
                          ) : (
                            <AiOutlineEyeInvisible />
                          )}
                        </div>
                      </div>
                      <div>
                        {this.state.error.target === "password" && (
                          <Alert
                            alertType={AlertType.DANGER}
                            title={"Error"}
                            description={this.state.error.msg}
                            close={() => {
                              this.setState({
                                error: { target: null, msg: "" },
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="-mt-3 mb-2">
                      {this.state.error.target === "main" && (
                        <Alert
                          alertType={AlertType.DANGER}
                          title={"Failed to login!"}
                          description={this.state.error.msg}
                          close={() => {
                            this.setState({
                              error: { target: null, msg: "" },
                            });
                          }}
                        />
                      )}
                    </div>
                    <div className="flex flex-row items-center justify-between gap-2 w-full mb-5 pt-5">
                      <button
                        disabled={this.state.loading}
                        className={`flex flex-row items-center justify-center ${
                          this.state.loading === true
                            ? "cursor-not-allowed"
                            : ""
                        } gap-2 bg-primary-800 text-white hover:bg-primary-800 rounded px-3 py-2 font-bold`}
                      >
                        <div>
                          {this.state.loading === true ? (
                            <AiOutlineLoading3Quarters className="text-xl animate-spin" />
                          ) : (
                            <AiOutlineLogin className="text-xl" />
                          )}
                        </div>
                        <span>
                          {this.state.loading === true
                            ? "Loading..."
                            : "Sign In"}
                        </span>
                      </button>
                      {this.state.loading === false && (
                        <div>
                          <div className="text-sm font-extralight text-black">
                            Forget password?
                          </div>
                          <div className="font-normal text-sm underline hover:text-primary-900 cursor-pointer">
                            Recover my password
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
                {/* semi footer */}
                <div className="px-2 md:px-10 py-10 font-light text-sm mt-20">
                  <div>Kigali Rwanda</div>
                  <div>Support: +250 789 037 077</div>
                  <div>Email: support@vms.rw</div>
                </div>
                <div className="border-t border-gray-400 pt-4 pb-10 px-5 text-sm font-extralight">
                  <div className="flex flex-row w-full items-center justify-between px-3">
                    <div>All rights reserved @copyright 2024</div>
                    <div>Designed by VMS DEV</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  system,
}: StoreState): { auth: Auth; system: System } => {
  return { auth, system };
};

export const Homepage = connect(mapStateToProps, {
  FC_Login,
  FC_GetSystemInfo,
})(_App);
