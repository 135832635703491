import { Auth, UserRoleInterface } from "../actions";

export enum UserAccessList {
  ALL = "ALL",
  REGISTER_USER = "REGISTER_USER",
  REGISTER_NATIONAL_CREDIT_MANAGER = "REGISTER_NATIONAL_CREDIT_MANAGER",
  REGISTER_VALUER = "REGISTER_VALUER",
  SYSTEM_USERS = "SYSTEM_USERS",
  INSTRUCTION_LETTERS = "INSTRUCTION_LETTERS",
  VALUATION_HISTORY = "VALUATION_HISTORY",
  ASSIGN_LETTER_SETTINGS = "ASSIGN_LETTER_SETTINGS",
  PAYMENT_SETTINGS = "PAYMENT_SETTINGS",
  LOAN_OFFICER_SETTINGS = "LOAN_OFFICER_SETTINGS",
  PAYMENTS = "PAYMENTS",
  BANKS_MANAGEMENT = "BANKS_MANAGEMENT",
  USER_LOGS = "USER_LOGS",
  NATIONAL_CREDIT_MANAGER_ASSISTANT = "NATIONAL_CREDIT_MANAGER_ASSISTANT",
  BRANCH_MANAGERS = "BRANCH_MANAGERS",
  VALUERS = "VALUERS",
  LOAN_OFFICERS = "LOAN_OFFICERS",
  ASSIGN_LETTER = "ASSIGN_LETTER",
  CONFIRM_LETTER_CASE = "CONFIRM_LETTER_CASE",
  USER_WALLET = "USER_WALLET",
  VALUER_COMPLETED_CASE = "VALUER_COMPLETED_CASE",
  HAVE_ONE_BANK = "HAVE_ONE_BANK", //To identify National Credit Manager
  HAVE_A_BRANCH = "HAVE_A_BRANCH", //To identify Branch manager/admin and loan officer
  HAVE_A_COMPANY = "HAVE_A_COMPANY", //To identify a valuer
  HAVE_MULTIPLE_BANKS = "HAVE_MULTIPLE_BANKS", //To identify a valuer having multiple banksß assigned
  RECOVERY_MANAGERS = "RECOVERY_MANAGERS",
  RECOVERY_OFFICERS = "RECOVERY_OFFICERS",
  BANK_SETTINGS = "BANK_SETTINGS", //New
}

export const FC_Access = (
  auth: Auth,
  access_item: UserAccessList
): {
  create: boolean;
  update: boolean;
  delete: boolean;
  view: boolean;
  export: boolean;
} => {
  let findAccess =
    auth.user &&
    auth.user.access &&
    auth.user.access.find((itm) => itm.key === access_item);
  if (findAccess !== undefined && findAccess !== null) {
    return findAccess.permission;
  } else {
    return {
      create: false,
      update: false,
      delete: false,
      view: false,
      export: false,
    };
  }
};

export const FilterRegisterAccessList = (
  auth: Auth,
  roles_list: UserRoleInterface[]
) => {
  const response: UserRoleInterface[] = [];
  for (const role of roles_list) {
    switch (role.role_id) {
      case "1": //Super admin
        auth.user?.role_id === "1" && response.push(role);
        break;
      case "2": //National credit manager
        (FC_Access(auth, UserAccessList.SYSTEM_USERS).create === true ||
          FC_Access(auth, UserAccessList.REGISTER_NATIONAL_CREDIT_MANAGER)
            .create === true) &&
          response.push(role);
        break;
      case "3": //National credit manager assistant
        (FC_Access(auth, UserAccessList.SYSTEM_USERS).create === true ||
          FC_Access(auth, UserAccessList.NATIONAL_CREDIT_MANAGER_ASSISTANT)
            .create === true) &&
          response.push(role);
        break;
      case "4": //Branch manager
        (FC_Access(auth, UserAccessList.SYSTEM_USERS).create === true ||
          FC_Access(auth, UserAccessList.BRANCH_MANAGERS).create === true) &&
          response.push(role);
        break;
      case "5": //Loan officer
        (FC_Access(auth, UserAccessList.SYSTEM_USERS).create === true ||
          FC_Access(auth, UserAccessList.LOAN_OFFICERS).create === true) &&
          response.push(role);
        break;
      case "6": //Valuer
        (FC_Access(auth, UserAccessList.SYSTEM_USERS).create === true ||
          FC_Access(auth, UserAccessList.VALUERS).create === true) &&
          response.push(role);
        break;
      case "7": //Recovery manager
        (FC_Access(auth, UserAccessList.SYSTEM_USERS).create === true ||
          FC_Access(auth, UserAccessList.RECOVERY_MANAGERS).create === true) &&
          response.push(role);
        break;
      case "8": //Recovery officer
        (FC_Access(auth, UserAccessList.SYSTEM_USERS).create === true ||
          FC_Access(auth, UserAccessList.RECOVERY_OFFICERS).create === true) &&
          response.push(role);
        break;

      default:
        auth.user?.role_id === "1" && response.push(role);
        break;
    }
  }

  return response;
};
