import { IconType } from "react-icons";
import {
  MdOutlineAccountBalanceWallet,
  MdOutlineDashboard,
} from "react-icons/md";
import { HiOutlineUser, HiOutlineUserGroup } from "react-icons/hi";
import { RiBankLine, RiLockPasswordLine } from "react-icons/ri";
import { UserAccessList } from "../utils/auth_access";
import { IoPersonAddOutline } from "react-icons/io5";
import { BsFileEarmarkMedical } from "react-icons/bs";
import { AiOutlineSetting } from "react-icons/ai";

export enum MENU_TYPE {
  NONE = "NONE",
  PROFILE = "PROFILE",
  ACTIVITIES = "ACTIVITIES",
}

export interface NavigationInterface {
  title: string;
  url: string;
}

export interface SideNavigationInterface {
  title: string;
  url: string;
  icon: IconType;
  label: string;
  menu_type: MENU_TYPE;
  access: UserAccessList | null;
}

/**
 * @description Appear allways
 * @done_by Emmy
 */
export const PUBLIC: NavigationInterface[] = [
  {
    title: "Login",
    url: "/login",
  },
];

/**
 * @description Appear once the user is not logged in
 * @done_by Emmy
 */
export const NON_AUTHENTICATED_MENUS: NavigationInterface[] = [
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Login",
    url: "/login",
  },
];

/**
 * @description Appear once the user is logged in
 * @done_by Emmy
 */
export const AUTHENTICATED_MENUS: SideNavigationInterface[] = [
  {
    icon: MdOutlineDashboard,
    title: "Dashboard",
    label: "Dashboard",
    url: "/dashboard",
    menu_type: MENU_TYPE.NONE,
    access: null,
  },
  {
    icon: HiOutlineUser,
    title: "Profile",
    label: "Profile",
    url: "/profile",
    menu_type: MENU_TYPE.PROFILE,
    access: null,
  },
  {
    icon: RiLockPasswordLine,
    title: "Change Password",
    label: "Change Password",
    url: "/change-password",
    menu_type: MENU_TYPE.PROFILE,
    access: null,
  },
  {
    icon: BsFileEarmarkMedical,
    title: "Instruction Letter",
    label: "Instruction Letter",
    url: "/instruction-letter",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.INSTRUCTION_LETTERS,
  },
  {
    icon: IoPersonAddOutline,
    title: "Register",
    label: "Register",
    url: "/register-user",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.REGISTER_USER,
  },
  {
    icon: RiBankLine,
    title: "Banks management",
    label: "Banks Management",
    url: "/banks-management",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.BANKS_MANAGEMENT,
  },
  {
    icon: HiOutlineUserGroup,
    title: "Users",
    label: "Users",
    url: "/users",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.REGISTER_USER, //SYSTEM_USERS
  },
  {
    icon: AiOutlineSetting,
    title: "Settings",
    label: "Settings",
    url: "/bank-settings",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.BANK_SETTINGS, //SYSTEM_USERS
  },
  {
    icon: MdOutlineAccountBalanceWallet,
    title: "Online wallet",
    label: "Online wallet",
    url: "/online-wallet",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.USER_WALLET,
  },
];

export const menus_categories = (): { key: MENU_TYPE; title: string }[] => {
  const response: { key: MENU_TYPE; title: string }[] = [];
  for (const menu in MENU_TYPE) {
    response.push({
      key: menu as MENU_TYPE,
      title:
        menu === MENU_TYPE.PROFILE
          ? "Profile"
          : menu === MENU_TYPE.ACTIVITIES
          ? "Activities"
          : "",
    });
  }
  return response.filter((element) =>
    response.find((itm) => itm.key === element.key)
  );
};
